@import 'newreset.css';
@import 'fonts.css';

/*
Media queries       | Defined   | Range
---------------------------------------------
X-Small             | V         | <576px
---------------------------------------------
Small               | V         | ≥576px
---------------------------------------------
Medium              | V         | ≥768px
---------------------------------------------
Large               | V         | ≥992px
---------------------------------------------
Extra large         | V         | ≥1200px
---------------------------------------------
Extra extra large   | V         | ≥1400px
*/

/* X-Small */
@media screen and (max-width: 576px) {
    :root {
        font-size: 40%;
    }
}

/* Small */
@media screen and (min-width: 576px) {
    :root {
        font-size: 50%;
    }
}

/* Medium */
@media screen and (min-width: 768px) {
    :root {
        font-size: 50%;
    }
}

/* Large */
@media screen and (min-width: 992px) {
    :root {
        font-size: 50%;
    }
}

/* Extra large */
@media screen and (min-width: 1200px) {
    :root {
        font-size: 62.5%;
    }
}

/* Extra extra large */
@media screen and (min-width: 1400px) {
    :root {
        font-size: 75%;
    }
}

body {
    font-family: 'Lexend Deca', sans-serif;

    overflow: hidden;
}

/* Border-box boilerplate that still allows padding-box and content-box */
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
